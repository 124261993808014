// This file is automatically generated; Do not edit.
const translationKeys: Record<string, string> = {
  booking_estimate_brreg_enterprise_org_search_group:
    'booking_estimate_brreg_enterprise_org_search_group',
  booking_estimate_deadline_text: 'booking_estimate_deadline_text',
  booking_estimate_exisiting_enterprise_org_search_group:
    'booking_estimate_exisiting_enterprise_org_search_group',
  booking_estimate_files_text: 'booking_estimate_files_text',
  booking_estimate_form_subtitle: 'booking_estimate_form_subtitle',
  booking_estimate_form_title: 'booking_estimate_form_title',
  booking_estimate_frame_agreement_banner:
    'booking_estimate_frame_agreement_banner',
  booking_estimate_from_text: 'booking_estimate_from_text',
  booking_estimate_price_tooltip: 'booking_estimate_price_tooltip',
  booking_estimate_provide_org_number_for_quote:
    'booking_estimate_provide_org_number_for_quote',
  booking_estimate_quote_unavailable_banner:
    'booking_estimate_quote_unavailable_banner',
  booking_estimate_request_submit_button:
    'booking_estimate_request_submit_button',
  booking_estimate_to_text: 'booking_estimate_to_text',
  booking_estimate_tooltip_label: 'booking_estimate_tooltip_label',
  booking_estimate_word_count: 'booking_estimate_word_count',
  contact_info_code_label: 'contact_info_code_label',
  contact_info_email_label: 'contact_info_email_label',
  contact_info_email_placeholder: 'contact_info_email_placeholder',
  contact_info_first_name_label: 'contact_info_first_name_label',
  contact_info_first_name_placeholder: 'contact_info_first_name_placeholder',
  contact_info_form_subtitle: 'contact_info_form_subtitle',
  contact_info_form_title: 'contact_info_form_title',
  contact_info_last_name_label: 'contact_info_last_name_label',
  contact_info_last_name_placeholder: 'contact_info_last_name_placeholder',
  contact_info_phone_label: 'contact_info_phone_label',
  contact_info_phone_placeholder: 'contact_info_phone_placeholder',
  contact_info_show_quote_button: 'contact_info_show_quote_button',
  finished_booking_case_number_label: 'finished_booking_case_number_label',
  finished_booking_case_number_placeholder:
    'finished_booking_case_number_placeholder',
  finished_booking_description_label: 'finished_booking_description_label',
  finished_booking_description_max_characters:
    'finished_booking_description_max_characters',
  finished_booking_description_placeholder:
    'finished_booking_description_placeholder',
  finished_booking_form_submitted_banner:
    'finished_booking_form_submitted_banner',
  finished_booking_form_subtitle: 'finished_booking_form_subtitle',
  finished_booking_form_title: 'finished_booking_form_title',
  finished_booking_header_subtitle: 'finished_booking_header_subtitle',
  finished_booking_header_subtitle_link:
    'finished_booking_header_subtitle_link',
  finished_booking_header_title: 'finished_booking_header_title',
  finished_booking_login_button: 'finished_booking_login_button',
  finished_booking_login_button_subtitle:
    'finished_booking_login_button_subtitle',
  finished_booking_other_subject_label: 'finished_booking_other_subject_label',
  finished_booking_other_subject_option:
    'finished_booking_other_subject_option',
  finished_booking_other_subject_placeholder:
    'finished_booking_other_subject_placeholder',
  finished_booking_request_header_title:
    'finished_booking_request_header_title',
  finished_booking_save_button: 'finished_booking_save_button',
  finished_booking_subject_label: 'finished_booking_subject_label',
  finished_booking_subject_placeholder: 'finished_booking_subject_placeholder',
  form_error_not_valid: 'form_error_not_valid',
  form_error_required: 'form_error_required',
  get_quote_subtitle: 'get_quote_subtitle',
  get_quote_title: 'get_quote_title',
  invoicing_details_add_department_checkbox:
    'invoicing_details_add_department_checkbox',
  invoicing_details_buyer_reference_label:
    'invoicing_details_buyer_reference_label',
  invoicing_details_buyer_reference_placeholder:
    'invoicing_details_buyer_reference_placeholder',
  invoicing_details_default_references_regex_hint:
    'invoicing_details_default_references_regex_hint',
  invoicing_details_department_none_option:
    'invoicing_details_department_none_option',
  invoicing_details_department_placeholder_without_org_number:
    'invoicing_details_department_placeholder_without_org_number',
  invoicing_details_department_text: 'invoicing_details_department_text',
  invoicing_details_enterprise_department_label:
    'invoicing_details_enterprise_department_label',
  invoicing_details_enterprise_department_placeholder:
    'invoicing_details_enterprise_department_placeholder',
  invoicing_details_enterprise_department_placeholder_new_customer:
    'invoicing_details_enterprise_department_placeholder_new_customer',
  invoicing_details_enterprise_department_required:
    'invoicing_details_enterprise_department_required',
  invoicing_details_enterprise_label: 'invoicing_details_enterprise_label',
  invoicing_details_my_enterprise_text: 'invoicing_details_my_enterprise_text',
  invoicing_details_new_department_label:
    'invoicing_details_new_department_label',
  invoicing_details_new_department_placeholder:
    'invoicing_details_new_department_placeholder',
  invoicing_details_order_reference_label:
    'invoicing_details_order_reference_label',
  invoicing_details_order_reference_placeholder:
    'invoicing_details_order_reference_placeholder',
  invoicing_details_org_number_label: 'invoicing_details_org_number_label',
  invoicing_details_org_number_placeholder:
    'invoicing_details_org_number_placeholder',
  invoicing_details_org_number_required:
    'invoicing_details_org_number_required',
  invoicing_details_org_number_search_hint:
    'invoicing_details_org_number_search_hint',
  invoicing_details_place_order_button: 'invoicing_details_place_order_button',
  invoicing_details_send_invoice_label: 'invoicing_details_send_invoice_label',
  invoicing_details_send_invoice_to_my_enterprise:
    'invoicing_details_send_invoice_to_my_enterprise',
  invoicing_details_send_invoice_to_other_enterprise:
    'invoicing_details_send_invoice_to_other_enterprise',
  invoicing_details_send_invoice_to_required:
    'invoicing_details_send_invoice_to_required',
  invoicing_details_title: 'invoicing_details_title',
  invoicing_details_your_department_label:
    'invoicing_details_your_department_label',
  invoicing_details_your_department_placeholder:
    'invoicing_details_your_department_placeholder',
  invoicing_details_your_department_required:
    'invoicing_details_your_department_required',
  logged_out_booking_default_error_message:
    'logged_out_booking_default_error_message',
  logged_out_booking_prices_note: 'logged_out_booking_prices_note',
  order_info_actions_delete: 'order_info_actions_delete',
  order_info_actions_download: 'order_info_actions_download',
  order_info_calculating_ocr_status_text:
    'order_info_calculating_ocr_status_text',
  order_info_customer_type_enterprise_option:
    'order_info_customer_type_enterprise_option',
  order_info_customer_type_label: 'order_info_customer_type_label',
  order_info_customer_type_private_option:
    'order_info_customer_type_private_option',
  order_info_deadline_date_picker_label:
    'order_info_deadline_date_picker_label',
  order_info_deadline_date_picker_placeholder:
    'order_info_deadline_date_picker_placeholder',
  order_info_deadline_error_banner: 'order_info_deadline_error_banner',
  order_info_deadline_hint: 'order_info_deadline_hint',
  order_info_error_empty_word_count: 'order_info_error_empty_word_count',
  order_info_error_estimating_word_count:
    'order_info_error_estimating_word_count',
  order_info_error_estimating_word_count_taking_time:
    'order_info_error_estimating_word_count_taking_time',
  order_info_error_upload_failed: 'order_info_error_upload_failed',
  order_info_file_uploader_label: 'order_info_file_uploader_label',
  order_info_form_subtitle: 'order_info_form_subtitle',
  order_info_form_title: 'order_info_form_title',
  order_info_language_dropdown_placeholder:
    'order_info_language_dropdown_placeholder',
  order_info_max_word_count_error: 'order_info_max_word_count_error',
  order_info_next_button: 'order_info_next_button',
  order_info_private_customer_not_supported_error:
    'order_info_private_customer_not_supported_error',
  order_info_retry_button: 'order_info_retry_button',
  order_info_source_language_label: 'order_info_source_language_label',
  order_info_source_language_required: 'order_info_source_language_required',
  order_info_target_languages_label: 'order_info_target_languages_label',
  order_info_target_languages_required: 'order_info_target_languages_required',
  order_info_uploading_status_text: 'order_info_uploading_status_text',
  order_info_word_count_input_hint: 'order_info_word_count_input_hint',
  order_info_word_count_label: 'order_info_word_count_label',
  order_info_word_count_or_divider_text:
    'order_info_word_count_or_divider_text',
  order_info_word_count_placeholder: 'order_info_word_count_placeholder',
  order_info_words_label: 'order_info_words_label',
  order_summary_additional_info_section:
    'order_summary_additional_info_section',
  order_summary_booker_title_text: 'order_summary_booker_title_text',
  order_summary_buyer_reference_title_text:
    'order_summary_buyer_reference_title_text',
  order_summary_case_number_title_text: 'order_summary_case_number_title_text',
  order_summary_deadline_title_text: 'order_summary_deadline_title_text',
  order_summary_description_title_text: 'order_summary_description_title_text',
  order_summary_details_section: 'order_summary_details_section',
  order_summary_enterprise_title_text: 'order_summary_enterprise_title_text',
  order_summary_files_title_text: 'order_summary_files_title_text',
  order_summary_from_title_text: 'order_summary_from_title_text',
  order_summary_invoicing_section: 'order_summary_invoicing_section',
  order_summary_order_reference_title_text:
    'order_summary_order_reference_title_text',
  order_summary_subject_title_text: 'order_summary_subject_title_text',
  order_summary_subtitle: 'order_summary_subtitle',
  order_summary_title: 'order_summary_title',
  order_summary_to_title_text: 'order_summary_to_title_text',
  order_summary_word_count_title_text: 'order_summary_word_count_title_text',
};

export default translationKeys;
