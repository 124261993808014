import React from 'react';
import SearchDropdown, { SearchDropdownMenuOption } from '../SearchDropdown/SearchDropdown';
import DefaultPhoneCodeList from './defaultPhoneCodeList';

interface Props {
  selected?: SearchDropdownMenuOption;
  options?: SearchDropdownMenuOption[];
  placeholder?: string;
  label?: string;
  onChange?: (item: SearchDropdownMenuOption[] | null) => void;
  onSearch?: (query: string) => void;
  disabled?: boolean;
  required?: boolean;
  errorText?: string;
}

const PhoneCodeDropdown = (props: Props) => {
  const {
    placeholder,
    options = DefaultPhoneCodeList,
    onChange,
    label,
    selected,
    onSearch,
    disabled,
    required,
    errorText,
  } = props;
  return (
    <SearchDropdown
      options={options}
      selected={selected ? [selected] : []}
      label={label}
      size="large"
      placeholder={placeholder}
      onSearch={onSearch}
      onChange={onChange}
      disabled={disabled}
      required={required}
      errorText={errorText}
    />
  );
};

export default PhoneCodeDropdown;
