interface DefaultPhoneCodeListOption {
  id: number;
  label: string;
  key: string;
}

const defaultPhoneCodeList: DefaultPhoneCodeListOption[] = [
  { id: 47, label: '+47', key: '+47' },
  { id: 355, label: '+355', key: '+355' },
  { id: 213, label: '+213', key: '+213' },
  { id: 244, label: '+244', key: '+244' },
  { id: 1264, label: '+1264', key: '+1264' },
  { id: 1268, label: '+1268', key: '+1268' },
  { id: 54, label: '+54', key: '+54' },
  { id: 374, label: '+374', key: '+374' },
  { id: 61, label: '+61', key: '+61' },
  { id: 43, label: '+43', key: '+43' },
  { id: 1242, label: '+1242', key: '+1242' },
  { id: 973, label: '+973', key: '+973' },
  { id: 1246, label: '+1246', key: '+1246' },
  { id: 32, label: '+32', key: '+32' },
  { id: 591, label: '+591', key: '+591' },
  { id: 267, label: '+267', key: '+267' },
  { id: 55, label: '+55', key: '+55' },
  { id: 1284, label: '+1284', key: '+1284' },
  { id: 359, label: '+359', key: '+359' },
  { id: 237, label: '+237', key: '+237' },
  { id: 1345, label: '+1345', key: '+1345' },
  { id: 236, label: '+236', key: '+236' },
  { id: 235, label: '+235', key: '+235' },
  { id: 56, label: '+56', key: '+56' },
  { id: 86, label: '+86', key: '+86' },
  { id: 57, label: '+57', key: '+57' },
  { id: 506, label: '+506', key: '+506' },
  { id: 225, label: '+225', key: '+225' },
  { id: 385, label: '+385', key: '+385' },
  { id: 357, label: '+357', key: '+357' },
  { id: 420, label: '+420', key: '+420' },
  { id: 243, label: '+243', key: '+243' },
  { id: 45, label: '+45', key: '+45' },
  { id: 1767, label: '+1767', key: '+1767' },
  { id: 593, label: '+593', key: '+593' },
  { id: 20, label: '+20', key: '+20' },
  { id: 503, label: '+503', key: '+503' },
  { id: 372, label: '+372)', key: '+372)' },
  { id: 679, label: '+679', key: '+679' },
  { id: 358, label: '+358', key: '+358' },
  { id: 33, label: '+33', key: '+33' },
  { id: 220, label: '+220', key: '+220' },
  { id: 49, label: '+49', key: '+49' },
  { id: 233, label: '+233', key: '+233' },
  { id: 30, label: '+30', key: '+30' },
  { id: 1473, label: '+1473', key: '+1473' },
  { id: 590, label: '+590', key: '+590' },
  { id: 502, label: '+502', key: '+502' },
  { id: 224, label: '+224', key: '+224' },
  { id: 245, label: '+245', key: '+245' },
  { id: 592, label: '+592', key: '+592' },
  { id: 504, label: '+504', key: '+504' },
  { id: 852, label: '+852', key: '+852' },
  { id: 36, label: '+36', key: '+36' },
  { id: 354, label: '+354', key: '+354' },
  { id: 91, label: '+91', key: '+91' },
  { id: 62, label: '+62', key: '+62' },
  { id: 353, label: '+353', key: '+353' },
  { id: 972, label: '+972', key: '+972' },
  { id: 39, label: '+39', key: '+39' },
  { id: 1876, label: '+1876', key: '+1876' },
  { id: 81, label: '+81', key: '+81' },
  { id: 962, label: '+962', key: '+962' },
  { id: 254, label: '+254', key: '+254' },
  { id: 965, label: '+965', key: '+965' },
  { id: 856, label: '+856', key: '+856' },
  { id: 371, label: '+371', key: '+371' },
  { id: 961, label: '+961', key: '+961' },
  { id: 423, label: '+423', key: '+423' },
  { id: 370, label: '+370', key: '+370' },
  { id: 352, label: '+352', key: '+352' },
  { id: 853, label: '+853', key: '+853' },
  { id: 389, label: '+389', key: '+389' },
  { id: 261, label: '+261', key: '+261' },
  { id: 60, label: '+60', key: '+60' },
  { id: 223, label: '+223', key: '+223' },
  { id: 356, label: '+356', key: '+356' },
  { id: 596, label: '+596', key: '+596' },
  { id: 230, label: '+230', key: '+230' },
  { id: 52, label: '+52', key: '+52' },
  { id: 373, label: '+373', key: '+373' },
  { id: 212, label: '+212', key: '+212' },
  { id: 264, label: '+264', key: '+264' },
  { id: 31, label: '+31', key: '+31' },
  { id: 64, label: '+64', key: '+64' },
  { id: 505, label: '+505', key: '+505' },
  { id: 227, label: '+227', key: '+227' },
  { id: 234, label: '+234', key: '+234' },
  { id: 968, label: '+968', key: '+968' },
  { id: 92, label: '+92', key: '+92' },
  { id: 680, label: '+680', key: '+680' },
  { id: 507, label: '+507', key: '+507' },
  { id: 595, label: '+595', key: '+595' },
  { id: 51, label: '+51', key: '+51' },
  { id: 63, label: '+63', key: '+63' },
  { id: 48, label: '+48', key: '+48' },
  { id: 351, label: '+351', key: '+351' },
  { id: 974, label: '+974', key: '+974' },
  { id: 262, label: '+262', key: '+262' },
  { id: 40, label: '+40', key: '+40' },
  { id: 7, label: '+7', key: '+7' },
  { id: 1869, label: '+1869', key: '+1869' },
  { id: 1758, label: '+1758', key: '+1758' },
  { id: 1784, label: '+1784', key: '+1784' },
  { id: 239, label: '+239', key: '+239' },
  { id: 966, label: '+966', key: '+966' },
  { id: 221, label: '+221', key: '+221' },
  { id: 248, label: '+248', key: '+248' },
  { id: 232, label: '+232', key: '+232' },
  { id: 65, label: '+65', key: '+65' },
  { id: 421, label: '+421', key: '+421' },
  { id: 386, label: '+386', key: '+386' },
  { id: 27, label: '+27', key: '+27' },
  { id: 82, label: '+82', key: '+82' },
  { id: 34, label: '+34', key: '+34' },
  { id: 94, label: '+94', key: '+94' },
  { id: 597, label: '+597', key: '+597' },
  { id: 268, label: '+268', key: '+268' },
  { id: 46, label: '+46', key: '+46' },
  { id: 41, label: '+41', key: '+41' },
  { id: 886, label: '+886', key: '+886' },
  { id: 255, label: '+255', key: '+255' },
  { id: 66, label: '+66', key: '+66' },
  { id: 1868, label: '+1868', key: '+1868' },
  { id: 216, label: '+216', key: '+216' },
  { id: 90, label: '+90', key: '+90' },
  { id: 1649, label: '+1649', key: '+1649' },
  { id: 256, label: '+256', key: '+256' },
  { id: 380, label: '+380', key: '+380' },
  { id: 971, label: '+971', key: '+971' },
  { id: 44, label: '+44', key: '+44' },
  { id: 1, label: '+1', key: '+1' },
  { id: 598, label: '+598', key: '+598' },
  { id: 58, label: '+58', key: '+58' },
  { id: 84, label: '+84', key: '+84' },
  { id: 967, label: '+967', key: '+967' },
  { id: 263, label: '+263', key: '+263' },
];

export default defaultPhoneCodeList;
