import React from 'react';
import { faChevronRight, faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import Dropdown, { DropdownItem } from '../Dropdown/Dropdown';
import IconButton from '../IconButton/IconButton';
import styles from './DetailItem.module.scss';

interface Props {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  preview?: React.ReactNode;
  children?: React.ReactNode;
  actions?: DropdownItem[];
  onActionSelect?: (selection: string | null) => void;
  onClick?: () => void;
  size?: 'small' | 'large';
  variant?: 'primary' | 'warning' | 'error' | 'selected' | 'archived';
  'data-testid'?: string;
}

const DetailItem = (props: Props) => {
  const {
    children,
    title,
    subtitle,
    preview,
    actions,
    onActionSelect,
    onClick,
    size = 'small',
    variant = 'primary',
  } = props;

  return (
    <div
      data-testid={props['data-testid']}
      className={classnames(styles.detailItem, styles[size], styles[variant], {
        [styles.clickable]: !!onClick,
      })}
      onClick={onClick}
    >
      {preview && (
        <div
          data-testid="detail-item-preview"
          className={classnames(variant === 'archived' && styles.contentOpacity)}
        >
          {preview}
        </div>
      )}
      <div
        className={classnames(
          styles.titleContainer,
          variant === 'archived' && styles.contentOpacity,
        )}
      >
        <span data-testid="detail-item-title" className={styles.title}>
          {title}
        </span>
        {subtitle && (
          <span className={styles.subtitle} data-testid="detail-item-subtitle">
            {subtitle}
          </span>
        )}
      </div>

      <div className={styles.rightSection}>
        {children && <div data-testid="detail-item-auxiliary-view">{children}</div>}
        {actions && actions.length === 1 && (
          <IconButton
            size={size === 'large' ? 'large' : 'medium'}
            data-testid="detail-item-single-action"
            hoverText={actions[0].text}
            onClick={() => {
              if (onActionSelect) {
                onActionSelect(actions[0].id);
              }
            }}
            icon={actions[0].icon ? <FontAwesomeIcon icon={actions[0].icon} /> : <></>}
          />
        )}

        {actions && actions.length > 1 && (
          <Dropdown
            data-testid="customer-action-more"
            toggle={
              <IconButton
                size="medium"
                data-testid="detail-item-actions-button"
                icon={<FontAwesomeIcon icon={faEllipsis} />}
                className={classnames(variant === 'archived' && styles.contentOpacity)}
              />
            }
            onSelect={onActionSelect}
            items={actions}
          />
        )}

        {onClick && (
          <FontAwesomeIcon icon={faChevronRight} data-testid="detail-item-clickable-icon" />
        )}
      </div>
    </div>
  );
};

export default DetailItem;
