import React from 'react';
import { Button as B_Button, ButtonProps, Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import Tooltip from '../Tooltip/Tooltip';
import styles from './IconButton.module.scss';

interface Props {
  icon: JSX.Element;
  size?: 'small' | 'medium' | 'large' | 'x-large';
  disabled?: boolean;
  isLoading?: boolean;
  hoverText?: string;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  className?: any;
  'data-testid'?: string;
}

const IconButton = (props: Props & Omit<ButtonProps, 'size'>) => {
  const {
    icon,
    size = 'medium',
    disabled = false,
    onClick,
    isLoading = false,
    hoverText,
    className,
    ...buttonProps
  } = props;

  const handleOnClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    if (isLoading) return;

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Tooltip title={hoverText}>
      <>
        {isLoading && (
          <Spinner animation="border" size="sm" className={styles.spinner} data-testid="spinner" />
        )}
        {!isLoading && (
          <B_Button
            className={classnames(styles.iconButton, styles[size], {
              [className]: !!className,
            })}
            variant=""
            disabled={disabled}
            data-testid={props['data-testid']}
            onClick={handleOnClick}
            {...buttonProps}
          >
            <span data-testid="icon" className={styles.icon}>
              {icon}
            </span>
          </B_Button>
        )}
      </>
    </Tooltip>
  );
};

export default IconButton;
