import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import {
  Option,
  TypeaheadInputProps,
  TypeaheadPropsAndState,
} from 'react-bootstrap-typeahead/types/types';
import { createIntl } from 'react-intl';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faMagnifyingGlass as faMagnifyingGlassLight } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown, faLocationDot, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage } from '@skiwo/utils';
import classnames from 'classnames';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Button from '../Button/Button';
import Spinner from '../Spinner/Spinner';
import TextField from '../TextField/TextField';
import languages from '../translations/languages';
import translationKeys from '../translations/translationKeys';
import styles from './SearchDropdown.module.scss';

export interface SearchDropdownMenuOption {
  id: number;
  label: string;
  subtitle?: string;
  group?: string;
  key?: string | number;
  icon?: IconDefinition;
}

interface Props {
  options: SearchDropdownMenuOption[];
  size?: 'medium' | 'large';
  placeholder?: string;
  grouped?: boolean;
  maxResults?: number;
  disabled?: boolean;
  multiple?: boolean;
  required?: boolean;
  label?: string;
  selected?: SearchDropdownMenuOption[];
  search?: true;
  hint?: string;
  searchAddress?: boolean;
  onChange?: (item: SearchDropdownMenuOption[] | null) => void;
  onSearch?: (query: string) => void;
  hideClearSelected?: boolean;
  isLoading?: boolean;
  errorText?: string;
}

const SearchDropdown = (props: Props) => {
  const {
    size = 'medium',
    placeholder,
    maxResults,
    options,
    grouped,
    onChange,
    label,
    multiple = false,
    search = false,
    searchAddress = false,
    disabled = false,
    required = false,
    isLoading = false,
    hideClearSelected = false,
    selected,
    hint,
    onSearch,
    errorText,
  } = props;
  const inputRef = useRef<any>();
  const userLanguage = getLanguage();
  const [selectedItems, setSelectedItems] = useState<SearchDropdownMenuOption[]>(selected || []);
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });

  const menuOptions: SearchDropdownMenuOption[] = options.filter(
    (item: SearchDropdownMenuOption) => !!item.label,
  );

  const filterBy = (option: any, state: TypeaheadPropsAndState) => {
    if (option.key && option.subtitle) {
      return (
        option.label.toLowerCase().indexOf(state.text.toLowerCase()) !== -1 ||
        option.label.toLowerCase().indexOf(state.text.toLowerCase().replace(/\s/g, '')) !== -1 ||
        option.subtitle?.toString().toLowerCase().indexOf(state.text.toLowerCase()) !== -1 ||
        option.subtitle
          ?.toString()
          .toLowerCase()
          .indexOf(state.text.toLowerCase().replace(/\s/g, '')) !== -1 ||
        option.key?.toString().toLowerCase().indexOf(state.text.toLowerCase()) !== -1 ||
        option.key
          ?.toString()
          .toLowerCase()
          .indexOf(state.text.toLowerCase().replace(/\s/g, '')) !== -1
      );
    }

    if (!option.key && option.subtitle) {
      return (
        option.label.toLowerCase().indexOf(state.text.toLowerCase()) !== -1 ||
        option.label.toLowerCase().indexOf(state.text.toLowerCase().replace(/\s/g, '')) !== -1 ||
        option.subtitle?.toString().toLowerCase().indexOf(state.text.toLowerCase()) !== -1 ||
        option.subtitle
          ?.toString()
          .toLowerCase()
          .indexOf(state.text.toLowerCase().replace(/\s/g, '')) !== -1
      );
    }

    if (option.key) {
      return (
        option.label.toLowerCase().indexOf(state.text.toLowerCase()) !== -1 ||
        option.label.toLowerCase().indexOf(state.text.toLowerCase().replace(/\s/g, '')) !== -1 ||
        option.key?.toString().toLowerCase().indexOf(state.text.toLowerCase()) !== -1 ||
        option.key
          ?.toString()
          .toLowerCase()
          .indexOf(state.text.toLowerCase().replace(/\s/g, '')) !== -1
      );
    }

    return (
      option.label.toLowerCase().indexOf(state.text.toLowerCase()) !== -1 ||
      option.label.toLowerCase().indexOf(state.text.toLowerCase().replace(/\s/g, '')) !== -1
    );
  };

  const getGroupedResults = (results: SearchDropdownMenuOption[]) => {
    if (!grouped) return null;

    const groupedOptions = results.reduce(
      (acc: { [group: string]: SearchDropdownMenuOption[] }, item: SearchDropdownMenuOption) => {
        if (item.group) {
          if (!acc[item.group]) {
            acc[item.group] = [];
          }
          acc[item.group].push(item);
        }
        return acc;
      },
      {},
    );

    return groupedOptions;
  };

  const handleOnChange = (selected: SearchDropdownMenuOption[]) => {
    if (multiple) {
      const lastSelected = selected.pop();
      if (!lastSelected) return;

      let newItems = selectedItems;

      if (selectedItems.map((item) => item.id).includes(lastSelected.id)) {
        newItems = newItems.filter((item) => item.id !== lastSelected.id);
      } else {
        newItems.push(lastSelected);
      }

      setSelectedItems(newItems);

      inputRef.current.toggleMenu();

      if (onChange) {
        onChange(newItems);
      }

      return;
    }

    if (onChange) {
      setSelectedItems(selected);
      onChange(selected);
    }
  };

  const handleClearSelection = () => {
    inputRef.current.clear();
    inputRef.current.blur();

    setSelectedItems([]);
    if (onChange) {
      onChange(null);
    }
  };

  useEffect(() => {
    setSelectedItems(selected || []);
  }, [selected]);

  const renderMenu = (results: Option[]) => {
    let options =
      !multiple && selectedItems.length
        ? (menuOptions as SearchDropdownMenuOption[])
        : (results as SearchDropdownMenuOption[]);
    const groupedOptions = getGroupedResults(options);

    if (multiple) {
      options = options.sort((firstItem, secondItem) => {
        const firstItemIndex = selectedItems.findIndex((obj) => obj.id === firstItem.id);
        const secondItemIndex = selectedItems.findIndex((obj) => obj.id === secondItem.id);

        if (firstItemIndex !== -1 && secondItemIndex === -1) {
          return -1;
        } else if (firstItemIndex === -1 && secondItemIndex !== -1) {
          return 1;
        }
        return options.indexOf(firstItem) - options.indexOf(secondItem);
      });
    }

    let menuContent = options.map((item: SearchDropdownMenuOption, index) => (
      <div key={item.id}>
        <MenuItem
          option={item}
          position={item.id}
          className={styles.menuItem}
          data-testid="dropdown-menu-item"
        >
          {multiple && (
            <Form.Check
              type="checkbox"
              checked={selectedItems.map((item) => item.id).includes(item.id)}
              onChange={() => null}
            />
          )}
          {item.icon && <FontAwesomeIcon icon={item.icon} data-testid="dropdown-menu-item-icon" />}
          <li key={item.id}>
            {item.label}
            {item.subtitle && (
              <span className={styles.subtitle} data-testid="dropdown-menu-item-subtitle">
                {item.subtitle}
              </span>
            )}
          </li>
        </MenuItem>
        <div
          className={classnames({
            [styles.divider]:
              multiple &&
              !(options.length === selectedItems.length) &&
              (selectedItems?.length === index + 1 ||
                options.filter((option) =>
                  selectedItems.find((selectedItem) => option.id == selectedItem.id),
                ).length ==
                  index + 1) &&
              selectedItems.some((selectedItem) => selectedItem.id == item.id),
          })}
        />
      </div>
    ));

    if (grouped && groupedOptions) {
      menuContent = Object.keys(groupedOptions).map((group) => {
        const groupSlug = group.replaceAll(' ', '-');
        const groupOptions = groupedOptions[group];

        return (
          <div key={groupSlug}>
            <span className={styles.groupLabel} data-testid="dropdown-menu-header">
              {group}
            </span>
            {groupOptions.map((item: SearchDropdownMenuOption) => (
              <MenuItem
                option={item}
                position={item.id}
                key={item.id}
                className={styles.menuItem}
                data-testid="dropdown-menu-item"
              >
                {multiple && (
                  <Form.Check
                    type="checkbox"
                    checked={selectedItems.includes(item)}
                    onChange={() => null}
                  />
                )}
                {item.icon && (
                  <FontAwesomeIcon icon={item.icon} data-testid="dropdown-menu-item-icon" />
                )}
                <li key={item.id}>
                  {item.label}
                  {item.subtitle && (
                    <span className={styles.subtitle} data-testid="dropdown-menu-item-subtitle">
                      {item.subtitle}
                    </span>
                  )}
                </li>
              </MenuItem>
            ))}
          </div>
        );
      });
    }

    const renderEmptyState = () => {
      return (
        <div className={styles.noItems} data-testid="empty-state-section">
          <FontAwesomeIcon className={styles.icon} icon={faMagnifyingGlassLight} />
          <span className={styles.description}>
            {intl.formatMessage({ id: translationKeys.search_dropdown_empty_items_state })}
          </span>
        </div>
      );
    };

    const renderLoadingState = () => {
      return (
        <div className={styles.noItems} data-testid="loading-state-section">
          <Spinner color="primary" />
          <span className={styles.description}>
            {intl.formatMessage({ id: translationKeys.search_dropdown_loading_state })}
          </span>
        </div>
      );
    };

    return (
      <Menu id="menu" className={styles.dropdownMenu} data-testid="dropdown-menu">
        <div
          className={classnames(styles.dropdownContent, {
            [styles.hasSelection]: selectedItems.length > 0 && !hideClearSelected,
          })}
        >
          {isLoading ? renderLoadingState() : menuContent.length ? menuContent : renderEmptyState()}
          {selectedItems.length > 0 && !hideClearSelected && (
            <div className={styles.clearSelection}>
              <Button
                variant="link"
                size="medium"
                onClick={handleClearSelection}
                data-testid="clear-button"
              >
                {intl.formatMessage({ id: translationKeys.dropdown_menu_clear_selected })}
              </Button>
            </div>
          )}
        </div>
      </Menu>
    );
  };

  const renderInput = (inputProps: TypeaheadInputProps) => {
    let inputPlaceholder = inputProps.placeholder;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, inputRef, referenceElementRef, inputClassName, ...modifiedProps } = inputProps;

    if (multiple && selectedItems.length > 0 && !inputProps.value) {
      inputPlaceholder = selectedItems.map((item) => item.label).join(', ');
    }

    return (
      <div className={styles.textField}>
        <TextField
          size={size}
          {...modifiedProps}
          ref={inputProps.inputRef}
          placeholder={inputPlaceholder}
          hint={hint}
          icon={
            search && searchAddress ? (
              <FontAwesomeIcon icon={faLocationDot} data-testid="search-icon" />
            ) : search && !searchAddress ? (
              <FontAwesomeIcon icon={faMagnifyingGlass} data-testid="search-icon" />
            ) : null
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (inputProps.onChange) {
              inputProps.onChange(e);
            }

            if (onSearch) {
              onSearch(e.currentTarget.value || '');
            }
          }}
          value={inputProps.value ? (inputProps.value as string) : ''}
          errorText={errorText}
        />
      </div>
    );
  };

  return (
    <div className={styles.searchDropdown}>
      <span data-testid="search-dropdown-label" className={styles.label}>
        {label}
      </span>
      {required && <span>*</span>}

      <div
        data-testid="search-dropdown"
        className={classnames(styles.dropdownContainer, { [styles.dropdownWithLabel]: label })}
      >
        <Typeahead
          id="search-dropdown"
          labelKey="label"
          selected={selectedItems}
          options={menuOptions}
          placeholder={placeholder}
          className={classnames(styles.searchDropdownInput, {
            [styles.hasDecoration]: !!search,
            [styles.hasSelectedItems]: selectedItems.length > 0,
          })}
          ref={inputRef}
          renderInput={renderInput}
          renderToken={() => <></>}
          multiple={multiple}
          disabled={disabled}
          minLength={search ? 1 : 0}
          maxResults={maxResults}
          size={size === 'large' ? 'lg' : undefined}
          onChange={(option) => handleOnChange(option as unknown as SearchDropdownMenuOption[])}
          filterBy={filterBy}
          paginate={false}
          renderMenu={renderMenu}
        />

        {!search && (
          <FontAwesomeIcon
            icon={faChevronDown}
            className={classnames(styles.decorationIcon, {
              [styles.medium]: size != 'large',
              [styles.hasHint]: hint,
              [styles.hasError]: errorText,
            })}
            data-testid="expand-chevron"
            onClick={() => inputRef.current.focus()}
          />
        )}
      </div>
    </div>
  );
};

export default SearchDropdown;
